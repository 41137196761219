<template>
  <div class="order">
    <!-- 题目 -->
    <top_title :title="$t('common.order')"></top_title>

    <div class="order full-width q-pa-md rtl">
      <!-- list -->
      <!-- <div v-if="order_list.length > 0" v-show="item.type !== 1" class="bg-grey-2 q-pa-sm e-border-r-8 q-mb-md" v-for="(item, index) in order_list" :key="index" @click="onclickOrder(item)"> -->
      <div class="bg-grey-2 q-pa-sm e-border-r-8 q-mb-md" v-for="(item, index) in order_list" :key="index" @click="onclickOrder(item)">
        <!-- top -->
        <div :class="['row justify-between q-py-sm', item.status == 1 ? 'e-b-b1-3' : item.status == 0 ? 'e-b-b1-2' : '']">
          <div class="text-grey-6">{{ item.releaseTime }}</div>
          <div :class="['Ukij', item.status == 1 ? 'e-text-main' : item.status == 0 ? 'text-red-12' : '']">{{ item.status == 1 ? $t('common.successOrder') : item.status == 0 ? $t('common.cancelOrder') : $t('common.error') }}</div>
        </div>

        <!-- content -->
        <div class="e-b-b1-1 q-py-md row justify-between text-grey-8" v-for="(con, index) in item.purchases" :key="index">
          <div class="width-80-p row justify-between">
            <div class="row">
              <div><q-img class="e-border-r-50" :src="con.goods ? con.goods.images[0].fullUrl : ''" style="width: 30px; height: 30px" /></div>
              <div class="Ukij q-mx-sm">{{ con.goods ? lang == 'ug' ? con.goods.name_ug : con.goods.name_zh : '' }}</div>
            </div>
            <div>x{{ con.count }}</div>
          </div>
          <div class="width-20-p row justify-end">{{ con.goods ? con.goods.price : '' }}￥</div>
        </div>

        <!-- bottom -->
        <div class="q-py-sm row justify-between">
          <div class="Ukij text-grey-6">{{ $t('common.total') }}:</div>
          <div class="e-text-main" v-if="item.type !== 9">{{ item.total_fee / 100 }}￥</div>
          <div class="e-text-main" v-if="item.type == 9">{{ item.total_fee_lunch_box_fee }}￥</div>
        </div>
      </div>
      
      <!-- 没有订单时提示 -->
      <div v-if="order_list.length == 0" class="rtl Ukij text-grey-7 text-center q-mt-xl">{{ $t('common.noOrder') }}</div>
    </div>

    <!-- tab -->
    <tab tab_active="2"></tab>
  </div>
</template>

<script>
import tab from '../../components/tab/tab'
import top_title from '../../components/topTitle/topTitle';
import { getPurchase } from '../../request/api';

export  default {
  name: 'order',
  components: {
    tab,
    top_title
  },
  data() {
    return {
      order_list: []
    }
  },
  
  methods: {
    // 获取订单
    getPost() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let user_id = userInfo.id

      // let user_id = 1;
      getPurchase(user_id).then(async res => {
        if(res.status == 200) {
          this.order_list = await res.data
        }
      }).catch(err => {
        console.log("err:", err)
      })

    },

    // 往订单详细页面
    onclickOrder(item) {
      let orderCon = JSON.stringify(item)
      this.$router.push({ path: '/orderDetailed', query: { orderCon: orderCon } })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
  },

  created () {
    this.getPost()
    this.scroll_top()
  }
}
</script>

<style>
.order { padding-bottom: 80px; }
</style>