<template>
    <router-view />
</template>

<script>
import VConsole from 'vconsole';
export default {
  name: 'LayoutDefault',
  created() {
    // const vConsole = new VConsole();
  }
}
</script>

<style>
@import "assets/costum.css";

</style>
