<template>
  <div class="complaints  cl"> 
    <!-- 题目 -->
    <top_title :title="$t('common.complaints')" :backIon=true></top_title>
    <!-- 输入投诉内框 -->
    <div class="rtl wdh">
      <span class="Ukij q-mr-md">{{ $t('common.contentofthecomplaint') }} :</span>
      <div class="q-pa-md jcc Ukij " style="max-width: 500px;">
          <q-input 
            v-model="content"
            filled
            type="textarea"
            label=""
            color="positive"
          />
      </div>
      <div><span class="Ukij q-mr-md">{{ $t('common.phone') }} :</span>
        <div><q-input class="rtl width-90-p q-mr-md q-mt-md q-mb-md" outlined v-model="phone_num" :dense="dense" color="positive" style="width: 358px;"/></div>
      </div>
    </div>
    <!-- 上传建议内容按钮 -->
    <div class="tac q-mt-xl Ukij ">
      <q-btn align="center" class="btn-fixed-width" color="green" :label="$t('common.send')" style="width: 358px;"  @click="onClickbtn" />
    </div>
  </div>
</template>

<script>
import top_title from '../../components/topTitle/topTitle.vue'
import { ref } from 'vue';
import { feedback } from '../../request/api';
export default {
  name: 'complaints',
  components: {top_title},
  data() {
    return {
      content: '',
      phone_num: '',
      dense: false
    }
  },
  methods:{
    // 创建反馈
    onClickbtn() {
      let data = {content: this.content, phone_num: this.phone_num}
      feedback(data).then(res =>{
        console.log('res:',res)
      }).catch(err => {
        console.log("err:", err)
      })
    }
  },
  created() {

  }
}
</script>

<style>
@import '../../assets/costum.css';
.mr{margin-right: 15px;}
.cl{color: rgb(151, 156, 156);}
.tac{text-align: center;}
.jcc{justify-content: center;}
.wdh{width: 100%;}
.width-90-p{width: 90%;}
.positive{color: #06cb00;}
</style>