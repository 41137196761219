export default {
    isoName: "ug-cn",
    nativeName: "hanzi",
    rtl: false,
    common: {
        home: '首页',
        order: '订单',
        mine: '用户中心',
        lang: '语言',
        contact: '联系',
        complaints: '意见建议',
        tn_about: '关于通吉努尔',
        balance: '余额',
        integral: '积分',
        tunji_nur: '通吉努尔美味',
        welcome: '欢迎',
        enterAnameForTheFood: '输入美食名称',
        featuredFood:'通吉努尔特色美食',
        category:'美食类目',
        preferential:'优惠美食',
        successOrder: '下单成功',
        cancelOrder: '取消订单',
        error: '错误',
        total: '总计',
        orderDetailed: '订单详细',
        orderNum: '订单号',
        orderTime: '订单时间',
        orderStatus: '订单状态',
        onOrder: '立即下单',
        yuan: '元',
        recharge:'充值',
        balancepage:'余额',
        redeem:'兑换积分',
        phone:'电话号',
        contentofthecomplaint:'请输入意见建议内容',
        send:'发送',
        myaddress:'我的地址',
        addaddress:'新增地址',
        address:'新增地址',
        shippingaddress:'收货地址',
        doorplate:'门牌号',
        phonenumber:'手机号',
        clickselect:'输入所在地址',
        fulladdress:'详细地址,例:B座13楼1035室',
        receivingmobile:'请填写收货手机号码',
        save:'保存',
        paymentCon: '提交订单',
        pleaseAddaddress: '请添加地址后下单',
        insufficientBalance: '余额不足',
        weChatPayment: '微信付款',
        paymentType: '付款类型',
        deliveryFees: '配送费',
        gratis: '免费',
        lunchBoxFee: '饭盒费',
        noAddress: '没有添加配送地址 ~',
        payment: '付款',
        dineIn: '堂食',
        himself: '自取',
        tunjinurDistribution: '通吉努尔配送',
        foodCategory: '选择饭菜类型',
        nothing: '什么也没有 :)',
        noOrder: '你还没有下单 :) ',
        quit: '退出',
        successfulOrder: '下单成功',
        finish: '完成'
    }
}