<template>
  <!-- 头部 -->
  <div class="paymentCon">
    <!-- 题目 -->
    <top_title :title="$t('common.paymentCon')" :backIon=true></top_title>
    
    <!-- 选择订餐类型 -->
    <div class="row q-px-sm rtl">
      <!-- BTN -->
      <div :class="['q-mt-sm width-33-p q-py-md text-center Ukij', paymentConBtnActive == index ? 'bg-light-blue-6 text-white' : 'bg-grey-3 text-grey-9']"
       v-for="(item, index) in meal_type"
       :key="item.value"
       @click="paymentConBtn(index)"
      >{{ $t(`common.${item.name}`) }}</div>

       <!-- box -->
      <div v-if="paymentConBtnActive == 0" class="bg-grey-2 full-width q-py-md q-px-sm row justify-between">
        <div class="row" @click="selectAddress">
          <div class="width-20"><q-img src="https://tunjinur.qiyat.cn/static/icons/location.svg" /></div>
          <div class="q-mr-sm"><span class="Ukij text-grey-9">{{ shippingAddressActive ? shippingAddressActive.addres_con : `${$t('common.noAddress')}` }}</span></div>
        </div>  
        <div class="width-20"><q-img src="https://tunjinur.qiyat.cn/static/icons/left_arrow.svg" /></div>  
      </div>

      <!-- 订餐信息 -->
      <div class="bg-grey-2 full-width q-pt-md q-px-sm q-mt-sm">
        <div class="row align-baseline justify-between q-mb-md" v-for="(item, index) in cartList" :key="index">
          <div class="row">
            <div style="width: 80px;"><q-img :src="item.goods.images[0].fullUrl" /></div>
            <div class="column justify-between q-mx-sm text-grey-9">
              <div class="Ukij">{{ lang == 'ug' ? item.goods.name_ug : item.goods.name_zh }}</div>
              <div>x{{ item.count }}</div>
            </div>
          </div>
          <!-- 价格 -->
          <div class="text-light-green-6">{{ item.price }}￥</div>
        </div>
      </div>
      
      <!-- 服务费 -->
      <div v-if="paymentConBtnActive !== 2" class="bg-grey-2 full-width q-mt-sm text-grey-9 q-pa-sm">
        <div v-if="paymentConBtnActive == 0" class="row justify-between Ukij q-mb-sm">
          <div>{{$t('common.deliveryFees')}}:</div>
          <div>{{$t('common.gratis')}}</div>
        </div>
        <div class="row justify-between Ukij">
          <div>{{$t('common.lunchBoxFee')}}:</div>
          <div>{{ lunch_box_fee }}￥</div>
        </div>
      </div>

    </div>

    <!-- 付款类型 -->
    <div class="row q-px-sm q-my-md rtl" style="margin-bottom: 200px;">
      <text v-if="company.length > 0 || yue > 0" class="q-mx-md text-negative Ukij" style="font-weight: bold;">{{$t('common.paymentType')}}:</text>
      <q-radio v-if="yue > 0" class="full-width Ukij" v-model="orderType" :disable=disableRadio val="1" :label=" disableRadio ? `${$t('common.insufficientBalance')}，`+ yue / 100 + '￥' : `${$t('common.balance')}，`+yue / 100 + '￥'" />
      <q-radio v-if="company.length > 0" class="full-width" v-model="orderType" val="2" :label="company" />
      <q-radio v-if="company.length > 0 || yue > 0" class="full-width Ukij" v-model="orderType" val="3" :label="$t('common.weChatPayment')" />
    </div>

    <!-- 付款 -->
    <div class="q-px-sm fixed full-width e-bt-10 row">
      <div class="bg-grey-9 q-py-md width-65-p q-px-lg text-grey-2 Ukij" style="border-radius: 20px 0 0 20px;">{{ $t('common.total') }}: {{ paymentConBtnActive !== 2 ? total_fee_lunch_box_fee / 100 : total_fee / 100 }}</div>
      <div class="bg-light-green-6 q-py-md width-35-p text-center text-grey-2 Ukij" style="border-radius: 0 20px 20px 0;" @click="onClickPayMent">{{$t('common.payment')}}</div>
    </div>

    <!-- 选择地址对话框 -->
    <q-dialog v-model="addressDialog" position="bottom">
      <q-card class="rtl">
        <q-card-section class="row items-center no-wrap">
          
          <q-list class="full-width">
            <q-item class="rtl" tag="label" v-ripple v-for="(item, index) in shippingAddress" :key="index">
              <q-item-section avatar>
                <q-radio v-model="shippingAddressActive" :val="item" color="teal" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="Ukij">{{ item.addres_con }}</q-item-label>
                <q-item-label caption>{{ item.phone_num }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>

        </q-card-section>
      
        <div class="q-my-xl text-center Ukij text-light-green" @click="addNewAddress">{{ $t('common.addaddress') }}</div>
      </q-card>
    </q-dialog>

    <!-- 付款结果提示对话框 -->
    <q-dialog v-model="paymentResultsDialog" persistent>
      <q-card class="q-pt-lg">

        <q-card-section class="q-pt-none column align-center q-px-xl">
          <div style="width: 30px; height: 30px;"> <q-img src="https://tunjinur.qiyat.cn/static/icons/paymentSuccessful.svg" /> </div>
          <div class="Ukij q-mt-sm text-grey-8">{{ $t('common.successfulOrder') }}</div>
        </q-card-section>

        <!-- <div class="Ukij full-width text-light-green-7 text-center q-py-sm border-t1-1" @click="paymentResultsBTN" v-close-popup>تامام</div> -->
        <q-btn flat class="Ukij full-width text-center q-py-sm border-t1-1 q-mt-lg text-white" style="background-color: #3fc43b !important;" :label="$t('common.finish')" @click="paymentResultsBTN" />
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import top_title from '../../components/topTitle/topTitle';
import { payment, getAddress, storePurchase, getFoodsByOutTradeNo } from '../../request/api';

export default {
  name: 'paymentCon',
  components: {
    top_title
  },
  data () {
    return {
      paymentConBtnActive: 0,
      shippingAddress: '', // 地址 Array
      shippingAddressActive: '',
      meal_type: [
        { name: 'tunjinurDistribution', value: 1 },
        { name: 'himself', value: 2 },
        { name: 'dineIn', value: 3 },
      ],
      orderType: '',
      addressDialog: false, // 选择地址对话框
      cartList: [], // 订单信息
      total_fee: 0, // 支付金额
      total_fee_lunch_box_fee: 0, // 支付金额加饭盒费
      lang: null,
      yue: 0,
      disableRadio: true, // 余额不足不让选
      company: '',
      paymentResultsDialog: false, // 付款结果对话框
      out_trade_no: 0, // 订单号
    }
  },
  
  computed: {
    // 饭盒钱
    lunch_box_fee () {
      return this.cartList.reduce((sum, item) => 
        sum + item.goods.lunch_box_fee * item.count
      , 0)
    }
  },
  methods: {
    // 订餐类型
    paymentConBtn(index) {
      this.paymentConBtnActive = index
      this.isYue(this.yue);
    },

    // 获取地址
    getsaveaddress () {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let user_id = userInfo.id

      // let user_id = 1

      getAddress(user_id).then(async res => {
        if(res.status == 200) {
          if(res.data.noAddress && res.data.noAddress == true) {
            this.yue = await res.data.balance.balance;
            this.company = res.data.workplace.name;
            return;
          }
          this.shippingAddress = res.data
          this.shippingAddressActive = res.data[0]
          if(res.data[0].balance) {
            this.yue = await res.data[0].balance.balance
          }
          if(res.data[0].workplace) {
            this.company = res.data[0].workplace.name
          }
          
        }
      }).catch(err => {
        console.log("err:", err)
      })

    },

    // 选择地址
    selectAddress() {
      this.addressDialog = true
    },

    // 添加新地址
    addNewAddress() {
      this.$router.push({ path: '/addaddress' })
    },

    // 获取用户信息
    getLocalUserInfo () {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 支付
    onClickPayMent () {

      /**
       * orderType = 1 余额下单
       * orderType = 2 单位下单
       * orderType = 3 微信下单
       * 默认微信下单
       */

      if(this.orderType == 3 || this.orderType == '') {
        if (this.shippingAddressActive.length == 0 && this.paymentConBtnActive == 0) {
          return this.$q.notify({
            position: 'center',
            icon: 'announcement',
            message: <span class="Ukij">{this.$t('common.pleaseAddaddress')}</span>
          })
        }
        let total_fee = this.paymentConBtnActive !== 2 ? this.total_fee_lunch_box_fee : this.total_fee
        let data = '';
        let that = this;
        // paymentConBtnActive = 1 (自取)、paymentConBtnActive = 2 (堂食)、paymentConBtnActive = 0 (外卖)
        if(this.paymentConBtnActive == 1) {
          data = { bodyCon: `支付金额为：${total_fee / 100}元`, total_fee: total_fee, type: 0, address_id: 0, outTradeNo: this.out_trade_no, meal_type: this.paymentConBtnActive }
        } else if(this.paymentConBtnActive == 2) {
          data = { bodyCon: `支付金额为：${total_fee / 100}元`, total_fee: total_fee, type: 0, address_id: -1, outTradeNo: this.out_trade_no, meal_type: this.paymentConBtnActive }
        } else if (this.paymentConBtnActive == 0) {
          data = { bodyCon: `支付金额为：${total_fee / 100}元`, total_fee: total_fee, type: 0, address_id: this.shippingAddressActive.id, outTradeNo: this.out_trade_no, meal_type: this.paymentConBtnActive }
        }
        payment(data).then(res => {
          // 生成订单方法
          // this.purchaseFun(res.data.out_trade_no)

          function onBridgeReady () {
            WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
              "appId": res.data.data.appId, //公众号ID，由商户传入     
              "timeStamp": res.data.data.timeStamp, //时间戳，自1970年以来的秒数     
              "nonceStr": res.data.data.nonceStr, //随机串     
              "package": res.data.data.package,
              "signType": res.data.data.signType, //微信签名方式：     
              "paySign": res.data.data.paySign //微信签名 
            },

            // 付款成功
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // alert(`支付成功，发起更新请求也成功了: ${res}`)
                // alert('支付成功')
                that.paymentResultsDialog = true;
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            });
          }
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          } else {
            onBridgeReady();
          }
        }).catch(err => {
          console.log("err: ", err)
        })
      } else {
        if (this.shippingAddressActive.length == 0 && this.paymentConBtnActive == 0) {
          return this.$q.notify({
            position: 'center',
            icon: 'announcement',
            message: <span class="Ukij">{this.$t('common.pleaseAddaddress')}</span>
          })
        }

        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let user_id = userInfo.id
        let workplace = userInfo.workplace_value
        
        // let workplace = 2
        // let user_id = 1

        let total_fee = this.paymentConBtnActive !== 2 ? this.total_fee_lunch_box_fee : this.total_fee
        let data = { id: user_id, total_fee: total_fee, type: parseInt(this.orderType, 10), workplace: workplace, address_id: this.shippingAddressActive.id, outTradeNo: this.out_trade_no, meal_type: this.paymentConBtnActive }
        payment(data).then(res => {
          if(res.status == 200) {
            this.paymentResultsDialog = true
          }
        }).catch(err => {})
        
      }
    },

    // 生成订单
    purchaseFun(nonceStr){
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let user_id = userInfo.id
      
      // let user_id = 1

      for (let item of this.cartList) {
        let purchase = { out_trade_no: nonceStr, price: item.price, user_id: user_id, count: item.num, goods_id: item.goods_id }
        storePurchase(purchase).then(res => { 
          if (res.status == 200) {
            console.log("已生成订单 ...")
          }
         }).catch(err => { console.log("err: ", err) })
      }
    },

    // 获取首页传来的购物车信息
    getOutTradeNo (){
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let user_id = userInfo.id
      
      // let user_id = 1

      let out_trade_no = this.$route.query.out_trade_no;
      this.out_trade_no = out_trade_no
      let data = { out_trade_no: out_trade_no, user_id: user_id }
      getFoodsByOutTradeNo(data).then(async res => {
        this.cartList = await res.data.purchases
        this.total_fee = await res.data.total_fee
        this.total_fee_lunch_box_fee = await res.data.total_fee_lunch_box_fee
        let balance = await res.data.balance.balance
        this.isYue(balance);
      }).catch(err => {})
    },

    // 关闭付款结果通知
    paymentResultsBTN() {
      this.paymentResultsDialog = false
      this.$router.push({
        path: '/'
      })
    },

    // 获取语言
    getLang () {
      if (localStorage.getItem('lang') == 'ug') {
        this.$i18n.locale = 'ug'
        this.lang_icon = 'lang-ug.svg'
        localStorage.setItem('lang', 'ug')
      } else if (localStorage.getItem('lang') == 'zh') {
        this.$i18n.locale = 'zh'
        this.lang_icon = 'lang-zh.svg'
        localStorage.setItem('lang', 'zh')
      } else {
        this.homeFullLanguageBox = true
      }

      this.lang = localStorage.getItem('lang');
    },

    // 让不让余额下单
    isYue(balance) {
      let sum_num = this.paymentConBtnActive !== 2 ? this.total_fee_lunch_box_fee : this.total_fee;
      this.disableRadio = sum_num <= balance ? false : true;
    }
   
  },

  created () {
    this.getLang();
    this.getsaveaddress();
    this.getOutTradeNo();
  }
}
</script>
<style>
@import '../../assets/costum.css';
</style>