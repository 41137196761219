<template>
     <div class="address q-pb-xl" style="margin-bottom: 100px;">

        <top_title :title="$t('common.myaddress')" :backIon=true></top_title>
        
        <div class="q-mt-md rtl">
          <div v-for="(item,index) in myaddress" :key="index" class="e-b-b1-3 row q-py-sm">
            <div class="width-80-p">
                <div class="q-pr-md text-blue-grey-13 Ukij">{{ item.addres_con }}</div>
                <div class="q-pr-md text-blue-grey-13">{{ item.phone_num }}</div>
            </div>

            <!-- 删除编辑 -->
            <div class="row justify-end items-center width-20-p">
              <q-img class="q-ml-md" style="width: 20px; height: 20px;" @click="onClickedit(item)" src="https://tunjinur.qiyat.cn/static/icons/edit.svg" />
              <q-img class="q-ml-md" style="width: 20px; height: 20px;" @click="onClickdelet(item)" src="https://tunjinur.qiyat.cn/static/icons/delet.svg" />
            </div>
          </div>
        </div>
       
        <div class="font-12 e-text-green">
          <div @click="onClickMainList">
            <div class="Ukij  fixed bt-0 DFcenter border-top full-width bg-white q-pb-xl q-pt-md">{{ $t('common.addaddress') }}
                <div class="q-pl-sm"><q-img style="width: 20px; height: 20px;" src="https://tunjinur.qiyat.cn/static/icons/add.svg" /></div>
            </div>
          </div>
        </div>

        <!-- 没有时提 -->
        <div v-if="myaddress.length == 0" class="rtl Ukij row justify-center q-mt-xl text-grey-6">{{ $t('common.noAddress') }}</div>

     </div>
</template>

<script>
import { QPopupEdit } from 'quasar';
import { ref } from 'vue';
import top_title from '../../components/topTitle/topTitle.vue';
import { getAddress, delAddress } from '../../request/api';

export default {
  name: 'address',
  components: { top_title },
  data() {
    return {
      myaddress:[]
    }
  },
  methods:{
    // main_list on click
    onClickMainList(){
        this.$router.push({path: '/addaddress' })
    },

    // 获取新增地址
    getsaveaddress(){
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let user_id = userInfo.id

      // let user_id = 1

      getAddress(user_id).then(res =>{
        if(res.data.noAddress) return;
        
        if(res.status == 200) {
          this.myaddress = res.data
        }
    }).catch(err => {
        console.log("err:", err)
      })
    },

    // 更改地址
    onClickedit(item){
      let userInfo = JSON.stringify(item)
      this.$router.push({
        path: '/addaddress',
        query: {
          userInfo: userInfo
        }
      })
    },

    // 删除地址
    onClickdelet(item){
      let data = { postId: item.id }
      
      delAddress(data).then(res => {
        if (res.status == 200) {
          const notif = this.$q.notify({
            position: 'center',
            type: 'ongoing',
            message: '正在删除...'
          })

          // simulate delay
          setTimeout(() => {
            notif({
              type: 'positive',
              position: 'center',
              message: '删除成功！',
              timeout: 1000
            })

            this.getsaveaddress()
          }, 3000)

        }
      }).catch(err => {
        console.log("err: ", err)
      })
    }
  },
  created(){
    this.getsaveaddress()
  }

}
</script>

<style>
@import '../../assets/costum.css';
.border-top{border-top: 1px solid rgb(207, 206, 206);}
</style>
