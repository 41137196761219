<template>
  <div class="about">
    <!-- 题目 -->
    <top_title :title="$t('common.tn_about')" :backIon=true></top_title>

    {{msg}}
    <!-- 产品介绍视频框 -->
      <div class="q-pa-md br ">
          <q-video
          :ratio="16/9"
          src="//v.qq.com/txp/iframe/player.html?origin=https%3A%2F%2Fmp.weixin.qq.com&containerId=js_tx_video_container_0.3560878402888348&vid=f3367piap6f&width=677&height=380.8125&autoplay=false&allowFullScreen=true&chid=17&full=true&show1080p=false&isDebugIframe=false"
          />
      </div>
      <!-- 介绍内容 -->
      <div class="cl rtl q-mt-sm Ukij"><p class="q-mr-sm">تۇنجى نۇر يىمەك - ئىچمەك چەكلىك مەسئۇلىيەت شىركىتى 2016-يىلى3-ئايدا قۇرۇلغان بولۇپ ، قەشقەر ، ئاتۇش ، ئۇستۈن ئاتۇش ، ئاقتۇ قاتارلىق جايلاردا زەنجىرسىمان تاماقخانىللىرى ۋە 200 دىن ئارتۇق ئىشچى خىزمەتچىللىرى بار بولغان مۇنتىزىم كارخانا . 
        <br>تاماقخانىمىز ئۆزەىچە بىزەلگەن ئازادە مۇھىتى ، ئەلا مۇلازىمىتى بىلەن كەسپى ئۇستاملار ئىخلاس بىلەن تەييارلىغان ئىسسىل تائاملىرى ، ساپ ھاۋالىق ئۇلۇغچات كاڭشىۋەر ئورمانچىلىق مەيدانىدا تۇنجى نۇرلۇقلارنىڭ ئۆز قولى بىلەن بىقىلغان تۈرلۈك ئۆي قۇشلىرى تائاملىرى ئۆزەىچە تەمى بىلەن تۇنجى نۇر لەززەتلىرىدە خەلقىمىزگە سۇنۇلدى . </p></div>
      <div class="df">
        <img src="https://img.xjlxw.com/d/file/lvyoujingdian/ats/8719df852814eba825a9e2bddac4fcc7.jpg?imageMogr2/thumbnail/550x" 
        alt="图片被服务员拿走了！" 
        style="width: 355px; height: 193px; border-radius: 5px;">
      </div>
  </div>
  
</template>

<script>
import top_title from '../../components/topTitle/topTitle.vue';

export default {
  name: 'about',
  components: { top_title },
  data() {
    return {
      msg: ''
    }
  }
}
</script>

<style>
@import '../../assets/costum.css';
.tac{text-align: center;}
.cl{color: rgb(79, 80, 80);}
.df{
  display: flex;
  justify-content: center;
}
.br{border-radius: 5px;}
</style>