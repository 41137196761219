<template>
  <div class="topTitle e-bg-main q-py-md row justify-between Ukij text-white full-width">
    <div class="q-mx-ms" @click="onClickBack">
      <q-img v-if="backIon" src="https://atush.vip/static/icons/leftArrow-white.svg" style="width: 25px" />
    </div>
    <div>{{title}}</div>
    <div class="q-mx-ms"></div>
  </div>
</template>

<script>
export default {
  name: 'topTitle',
  props: {
    ['title']: String,
    backIon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      msg: 'hello topTitle ~'
    }
  },

  methods: {
    onClickBack() {
      if (window.history.length <= 1) {
        this.$router.push({path:'/'})
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style>
@import "/src/assets/costum.css";
</style>
