<template>
  <div class="orderDetailed">
    <!-- 题目 -->
    <top_title :title="$t('common.orderDetailed')" :backIon=true></top_title>

    <!-- bottom -->
    <div class="q-px-md fixed bt-0 full-width bg-white border-t1-1 q-py-sm zIndex-1 bg-green-1">
      <div class="row justify-between q-mb-md">
          <div class="e-text-red-1">{{ lunch_box_fee }}￥</div>
          <div class="Ukij text-grey-8">:قاچا ھەققى</div>
        </div>
      <div class="row justify-between q-mb-md">
        <div class="e-text-red-1" v-if="foodOrder.type !== 9">{{foodOrder.total_fee / 100}}￥</div>
        <div class="e-text-red-1" v-if="foodOrder.type == 9">{{foodOrder.total_fee_lunch_box_fee }}￥</div>
        <div class="Ukij text-grey-8">:{{$t('common.total')}}</div>
      </div>
      <div class="row justify-between q-mb-md">
        <div class="text-grey-8">{{foodOrder.out_trade_no}}</div>
        <div class="Ukij text-grey-8">:{{$t('common.orderNum')}}</div>
      </div>
      <div class="row justify-between q-mb-md">
        <div class="text-grey-8">{{foodOrder.releaseTime}}</div>
        <div class="Ukij text-grey-8">:{{$t('common.orderTime')}}</div>
      </div>
      <div class="row justify-between q-mb-md">
        <div :class="['Ukij', foodOrder.status == 1 ? 'e-text-main' : foodOrder.status == 0 ? 'e-text-red-1' : '']">{{foodOrder.status == 1 ? `${$t('common.successOrder')}` : foodOrder.status == 0 ? `${$t('common.cancelOrder')}` : `${$t('common.error')}`}}</div>
        <div class="Ukij text-grey-8">:{{$t('common.orderStatus')}}</div>
      </div>
    </div>

    <!-- list -->
    <div class="row justify-between q-px-md q-mt-md height-70 e-last-b-200" v-for="(item, index) in foodOrder.purchases" :key="index">
      <div class="row">
        <div><q-img class="e-border-r-10" :src="item.goods.images[0].fullUrl" style="width: 100px; height: 70px" /></div>
        <div class="row q-mx-md items-end">
          <div class="q-mr-xl width-20 text-end text-grey-8">￥{{item.goods.price}}</div>
          <div class="width-20 text-end text-grey-5">x{{ item.count }}</div>
        </div>
      </div>
      <div class="Ukij text-grey-8">{{ item.goods.name_ug }}</div>
    </div>

  </div>
</template>

<script>
import top_title from '../../components/topTitle/topTitle';

export default {
  name: 'orderDetailed',
  components: { top_title },
  data() {
    return {
      foodOrder: {},
      order_id: 0, // 
    }
  },
  computed: {
    // 饭盒钱
    lunch_box_fee () {
      return this.foodOrder.purchases.reduce((sum, item) =>
        sum + item.goods.lunch_box_fee
        , 0)
    }
  },

  methods: {
    // 订单详细
    foodCon() {
      this.foodOrder = JSON.parse(this.$route.query.orderCon)
    }
  },

  created() {
    this.foodCon();
  }
}
</script>

<style>

</style>