<template>
  <div class="filled">
    <!-- 题目 -->
    <top_title :title="$t('common.balancepage')" :backIon=true></top_title>

    <!-- 可用余额 -->
    <div class="wh e-bg-main height-100 df pr">
      <!-- 余额显示 -->
      <div class="height-200 pa width-300 bgc shadow-1 br tac Ukij">
          <p class="q-mt-sm">{{ $t('common.balance') }} 
            <q-icon
              :name="isPwd == true ? 'visibility' : isPwd == false ? 'visibility_off' : ''"
              class="cursor-pointer"
              @click="onClickIsPwd"
            />
          </p>
        <div class="font-30">
          <p v-if="isPwd">{{ balanceCon ? balanceCon.balance / 100 : '0.00' }}</p>
          <p v-if="!isPwd">***</p>
        </div>
        <!-- 充值按钮 -->
        <div class="q-mt-xl">
          <q-btn class="Ukij" unelevated color="light-blue-6" :label="$t('common.recharge')" @click="rechargeBTN" />
        </div>
      </div>
    </div>

    <!-- 充值对话框 -->
    <q-dialog v-model="rechargeDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">充值余额</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model.number="amount" autofocus />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="取消" v-close-popup />
          <q-btn flat label="确定" v-close-popup @click="rechargePayment" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { payment, getByIdBalance, changeBalanceCon } from '../../request/api';
import top_title from '../../components/topTitle/topTitle.vue';

import { ref } from 'vue'
export default {
  name: 'filled',
  components: { top_title },
  data() {
    return {
      password: ref(''),
      isPwd: true,
      rechargeDialog: false,
      amount: 0, // 充值金额
      balanceCon: '', // 余额
    }
  },
  methods: {
    // 是否显示余额
    onClickIsPwd() {
      this.isPwd = !this.isPwd
    },

    // 从我的页面获取余额信息
    getBalanceCon () {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      getByIdBalance(userInfo.id).then(res => {
        this.balanceCon = res.data
      }).catch(err => {
        console.log("err: ", err)
      })
    },

    // 充值对话框
    rechargeBTN() {
      this.rechargeDialog = true
    },

    // 更新余额
    updateBalanceCon() {
      let total_fee = this.amount * 100;
      let data = { total_fee: total_fee };
      changeBalanceCon(data).that(res => {
        console.log(res, "更新余额成功 ~")
      }).catch(err => {
        console.log(err , "err")
      })
    },

    // 充值付款
    rechargePayment() {
      let total_fee = this.amount * 100;
      let balanceType = 1;
      let that = this;
      let data = { bodyCon: `充值金额为：${total_fee / 100}元`, total_fee: total_fee, balanceType: balanceType };
      payment(data).then(res => {
        function onBridgeReady () {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            "appId": res.data.data.appId, //公众号ID，由商户传入     
            "timeStamp": res.data.data.timeStamp, //时间戳，自1970年以来的秒数     
            "nonceStr": res.data.data.nonceStr, //随机串     
            "package": res.data.data.package,
            "signType": res.data.data.signType, //微信签名方式：     
            "paySign": res.data.data.paySign //微信签名 
          },

          // 付款成功
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // alert(`支付成功，发起更新请求也成功了: ${res}`)
              alert('充值成功')
              that.updateBalanceCon();
              that.getBalanceCon();
              that.amount = 0;
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            }
          });
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      }).catch(err => {
        console.log("err: ", err)
      })
    }
  },

  created() {
    this.getBalanceCon()
  }
}
</script>

<style>
@import '../../assets/costum.css';

.df{display: flex;}
.pr{position: relative;}
.pa{
  position: absolute;
  top:10px;
}
.height-100{height: 60px;}
.width-300{width: 350px;}
.bgc{background-color: #fff;}
.br{border-radius: 10px;}
.tac{text-align:center;}
.font-30{font-size: 30px;}
</style>