import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home/home.vue'
import order from '../views/order/order'
import mine from '../views/mine/mine'
import orderDetailed from '../views/orderDetailed/orderDetailed'
import filled from '../views/filled/filled'
import integral from '../views/integral/integral'
import complaints from '../views/complaints/complaints'
import about from '../views/about/about'
import address from '../views/address/address'
import addaddress from '../views/addaddress/addaddress'
import paymentCon from '../views/paymentCon/paymentCon'
const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/mine',
    name: 'mine',
    component: mine
  },
  {
    path: '/orderDetailed',
    name: 'orderDetailed',
    component: orderDetailed
  },
  {
    path: '/filled',
    name: 'filled',
    component: filled
  },
  {
    path: '/integral',
    name: 'integral',
    component: integral
  },
  {
    path: '/complaints',
    name: 'complaints',
    component: complaints
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path:'/address',
    name:'address',
    component: address
  },
  {
    path:'/addaddress',
    name:'addaddress',
    component:addaddress
  },
  {
    path:'/paymentCon',
    name:'paymentCon',
    component: paymentCon
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
