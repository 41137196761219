<template>
  <div class="integral">
    <!-- 题目 -->
    <top_title :title="$t('common.integral')" :backIon=true></top_title>

    <!-- 积分显示兰 -->
    <div class="e-bg-main height-100">
      <div class="e-bg-main text-center text-white q-pt-md fs Ukij">{{ $t('common.integral') }}: {{ balanceCon ? balanceCon.points : '0'}}</div>
    </div>
    <div class="Ukij q-mt-xl display-flex justify-center"><q-btn unelevated rounded color="positive" :label="$t('common.redeem')" /></div>
  </div>
</template>

<script>
import top_title from '../../components/topTitle/topTitle.vue';
import { getByIdBalance } from '../../request/api';

export default {
  name: 'integral',
  components: { top_title },
  data() {
    return {
     balanceCon: '', // 余额
    }
  },

  methods: {
    // 从我的页面获取余额信息
    getBalanceCon () {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      getByIdBalance(userInfo.id).then(res => {
        this.balanceCon = res.data
      }).catch(err => {
        console.log("err: ", err)
      })
    }
  },

  created () {
    this.getBalanceCon()
  }
}
</script>

<style>
.positive1{background-color: #06cb00;}
.height-100{height: 100px;}
.fs{font-size: 20px;}
.display-flex{display: flex;}
</style>