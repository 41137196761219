import { get, post, deleteMethod, patch } from "./http.js";

/**
 * *******************************************************
 * 获取菜单
 */

// 添加房屋
const houseAdd = (params) => {
  let url = "/house";
  return post(url, params);
};

// 获取菜单
const getFoods = (pageNo, status, value, pageSize) => {
  let url = "/foods";
  url = `/foods?pageNo=${pageNo}&status=${status}&value=${value}&pageSize=${pageSize}`;
  return get(url);
};

// 获取菜单(按 ID 查询)
const getIdFood = (params) => {
  let url = `/food/${params}`;
  return get(url, params);
};

// 获取类型
const getCategory = () => {
  let url = "/category";
  return get(url);
};

// 轮播图
const swipers = () => {
  let url = "getswiper";
  return get(url);
};

// 状态
const houseStatus = (params) => {
  let url = "/houseStatus/";
  if ("id" in params) url += params.id;
  return patch(url, params);
};

// 反馈 意见建议
const feedback = (params) => {
  let url = "/feedback";
  return post(url, params);
};
// 新增地址
const addAddress = (params) => {
  let url = "/addAddress";
  return post(url, params);
};
// 获取地址
const getAddress = (params) => {
  let url = `/address/${params}`;
  return get(url,params);
};
// 上传文件
const houseFile = (params, data) => {
  const { target_id, target_type } = data;
  let url = `/houseFiles`;
  if (target_id) {
    url = `/houseFiles?target_type=${target_type}&target_id=${target_id}`
  }
  return post(url, params);
};

// 删除文件
const houseDelFile = (params) => {
  let url = "/getHouseFilesId";
  return post(url, params);
};

// 获取用户 code
const reqLogin = (params) => {
  let url = "/getCode";
  return get(url, params);
};

// 获取用户信息
const getuserInfo = (params) => {
  let url = "/getuserInfo";
  return post(url, params);
};

// 统一下单
const payment = (params) => {
  let url = "/payments";
  return post(url, params);
};

// 删除地址
const delAddress = (params) => {
  let url = "/addressDestroy";
  return post(url, params);
};

// 获取订单 
const userOrder = (params) => {
  let url = "/getUserOrder";
  return post(url, params);
};

// 创建订单 
const storePurchase = (params) => {
  let url = "/purchase";
  return post(url, params);
};

// 获取订单
const getPurchase = (params) => {
  let url = `/purchase/${params}`;
  return get(url, params);
};

// 获取订单(按订单号)
const getFoodsByOutTradeNo = (params) => {
  let url = `/getByOutTradeNo`;
  return post(url, params);
};

// 单位或余额付款
const changeBalance = (params) => {
  let url = "/updateBalance";
  return post(url, params);
}

// 单位或余额付款
const getBalance = (params) => {
  let url = "/byUseridbalance";
  return get(url, params);
}

// 获取订单
const getByIdBalance = (params) => {
  let url = `/balance/${params}`;
  return get(url, params);
};

// 更新余额
const changeBalanceCon = (params) => {
  let url = "/changeBalance";
  return post(url, params);
}

export {
  getFoods,
  getIdFood,
  getCategory,
  swipers,
  feedback,
  addAddress,
  getAddress,
  reqLogin,
  getuserInfo,
  payment,
  delAddress,
  userOrder,
  storePurchase,
  getPurchase,
  getFoodsByOutTradeNo,
  changeBalance,
  getBalance,
  getByIdBalance,
  changeBalanceCon
};
