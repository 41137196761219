export default {
    isoName: "ug-cn",
    nativeName: "uyghur",
    rtl: true,
    common: {
        home: 'باش بەت',
        order: 'زاكاسلىرىم',
        mine: 'ئەزا مەركىزى',
        lang: 'تىل',
        contact: 'ئالاقىلشىش',
        complaints: 'تەكلىپ پىكىر',
        tn_about: 'تۇنجى نۇر ھەققىدە',
        balance: 'قالدۇق سومما',
        integral: 'جۇغلانما نۇمۇر',
        tunji_nur: 'تۇنجى نۇر لەززەت تائاملىرى',
        welcome: 'خۇش كەلدىڭىز',
        enterAnameForTheFood: 'تائام ئىسمىنى كىرگۈزۈڭ',
        featuredFood: 'تۇنجى نۇر ئالاھىدە تائاملىرى',
        category:'تاماق تۈرى',
        preferential:'ئتىبار تاماق',
        successOrder: 'مۇۋاپىقىيەتلىك بولدى',
        cancelOrder: 'بىكار قىلىنغان',
        error: 'خاتالىق',
        total: 'جەممىي',
        orderDetailed: 'زاكاس تەپسىلاتى',
        orderNum: 'زاكاس نۇمۇرى',
        orderTime: 'زاكاس ۋاقتى',
        orderStatus: 'زاكاس ھالىتى',
        onOrder: 'زاكاس چۈشۈرۈش',
        yuan: 'يۈەن',
        recharge:'پۇل قاچىلاش',
        balancepage:'قىممەت تولۇقلاش',
        redeem:'جۇغلانما نۇمۇرنى ئالماشتۇرۇش',
        phone:'تىلفۇن نۇمۇر',
        contentofthecomplaint:'تەكلىپ پىكىر مەزمۇنىنى كىرگۈزۈڭ ',
        send:'تەكلىپ پىكىر يوللاش',
        myaddress:'ئادرسم',
        addaddress:'يېڭى ئادرېس قوشۇش',
        address:'ئادىرېس قوشۇش',
        shippingaddress:'ئورنىىڭىز',
        doorplate:'ئۆي ئورنى',
        phonenumber:'تېلىفون',
        clickselect:'ئورنىىڭىزنى كىرگۈزۈڭ',
        fulladdress:'تەپسىلى ئادرېسڭىزنى كىرگۈزۈڭ ',
        receivingmobile:'تېلىفون نومۇرىڭزنى تولدۇرۇڭ',
        save:'ساقلاش',
        paymentCon: 'زاكاس تاپشۇرۇش',
        pleaseAddaddress: 'ئاىرىس قوشۇپ زاكاس چۈشۈرۈڭ',
        insufficientBalance: 'قالدۇق سومما يەتمىدى',
        weChatPayment: 'ئۈندىداردا پۇل تۆلەش',
        paymentType: 'پۈل تۆلەش شەكلى',
        deliveryFees: 'يەتكۈزۈش ھەققى',
        gratis: 'ھەقسىز',
        lunchBoxFee: 'قاچا ھەققى',
        noAddress: 'يەتكۈزۈش ئادىرىسى يوقكەن ~',
        payment: 'پۈل تۆلەش',
        dineIn: 'ئاشخانىدا',
        himself: 'ئۆزۈم ئېلىۋالاي',
        tunjinurDistribution: 'تۈنجى نۇر يەتكۈزىدۇ',
        foodCategory: 'تاماق تۈرىنى تاللاڭ',
        nothing: 'ھېششىمۇ يوق ~',
        noOrder: 'تېخى زاكاس چۈشۈرمەپسىز :)',
        quit: 'چىكىنىش',
        successfulOrder: 'زاكاس چۈشۈرۈش مۇۋاپىقىيەتلىك بولدى',
        finish: 'تامام',
    }
}