<template>
  <div class="tab">
    <!-- tab -->
    <div class="row fixed bt-0 rtl full-width Ukij border-t1-1 bg-white">
      <div class="width-33-p column justify-center items-center q-py-sm" v-for="(item, index) in tab_list" :key="index" @click="onClickTab(item)">
        <div><q-img style="width: 30px; height: 30px;" :src="tab_active == item.id ? icon_url+item.ac_icon: icon_url+item.icon" /></div>
        <div :class="['q-mt-sm', tab_active == item.id ? 'e-text-main': 'text-grey-6']">{{ $t(`common.${item.name}`) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'tab',
    props: {
      tab_active: {
        type: String,
        default: '0'
      }
    },
    data() {
      return {
        icon_url: 'https://tunjinur.qiyat.cn/static/icons/', 
        tab_list: [
          { id: 1, name: 'home', icon: 'home.svg', ac_icon: 'home-fill.svg' },
          { id: 2, name: 'order', icon: 'order.svg', ac_icon: 'order-fill.svg' },
          { id: 3, name: 'mine', icon: 'man.svg', ac_icon: 'man-fill.svg' },
        ]
      }
    },

    methods: {
      onClickTab(item) {
        switch (item.id) {
          case 1:
            this.$router.push('/')
            break;
          case 2:
            this.$router.push('/order')
            break;
          case 3:
            this.$router.push('/mine')
            break;
          default:
            console.log("默认");
            break;
        }
      }
    }
  }
</script>

<style>
@import "/src/assets/costum.css";

</style>