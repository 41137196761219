import axios from "axios";

// 请求拦截器 ----> 请求之前对axios做一些配置
axios.interceptors.request.use(
  (conifg) => {

    conifg.baseURL = "https://api-tunjinur.qiyat.cn";
    // conifg.baseURL = "http://localhost:3005";
    conifg.timeout = 10000;
    conifg.headers["Content-Type"] = "application/json; charset=utf8";
    conifg.headers["authentication"] = sessionStorage.getItem("authentication");
    conifg.headers["token"] = sessionStorage.getItem("token");
    // conifg.withCredentials = true;
    return conifg;
  },
  (error) => {
    console.log(error);
  }
);

//响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log(error);
  }
);

const get = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        console.log(error.data);
      });
  });
};

const post = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        console.log(error.data);
      });
  });
};

const deleteMethod = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        console.log(error.data);
      });
  });
};

const patch = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        console.log(error.data);
      });
  });
};

export { get, post, deleteMethod, patch };
