<template>
  <!-- 头部 -->
  <div class="home">
    <!-- 满屏选语言 -->
    <div v-if="homeFullLanguageBox" class="bg-white fixed e-t-0 full-width full-height zIndex-1 column align-center justify-between q-pb-xl">
      <div class="bg-green-6 full-width row align-center justify-center" style="height: 300px; border-radius: 0 0 50% 50%;">
        <div>
          <div><q-img src="https://tunjinur.qiyat.cn/static/icons/TN_LOGO.svg" /></div>
          <div class="text-white text-center" style="margin-top: -20px; font-weight: 900; font-size: 32px;">TUNJINUR</div>
        </div>
      </div>
      <div>
        <div class="Ukij"><q-btn style="width: 150px;" color="green-6 q-py-md" label="ئۇيغۇرچە" @click="setLanguage('ug')" /></div>
        <div class="q-my-md"><q-btn style="width: 150px;" color="green-6 q-py-md" label="中文" @click="setLanguage('zh')" /></div>
      </div>
      
      <div class="text-grey-8">
        <div class="Ukij">ئاتۇش شەھىرى تۇنجى نۇر لەززەتلىرى</div>
        <div>阿图什市通吉努尔美味餐饮店</div>
      </div>
    </div>

    <!-- 题目 -->
    <top_title :title="$t('common.home')"></top_title>

    <!-- 搜索 -->
    <!-- <div class="q-px-sm q-mt-sm">
      <q-input class="Ukij rtl" v-model="search" filled :placeholder="$t('common.enterAnameForTheFood')"
        color="positive">
        <template v-slot:append>
          <q-icon name="search" color="positive" />
        </template>
      </q-input>
    </div> -->

    <!-- 轮播图 -->
    <div class="q-px-sm q-mt-md">
      <q-carousel style="height: 150px; border-radius: 10px; overflow: hidden;" swipeable animated thumbnails v-model="slide" infinite :autoplay="autoplay" transition-prev="slide-right"
        transition-next="slide-left">
        <q-carousel-slide :name="index" v-for="(item, index) in swipers" :key="index" :img-src="item.cover.fullUrl" @click="onClickSwiper(item)"/>
      </q-carousel>
    </div>

    <!-- 优惠-活动 -->
    <div class="Ukij text-center q-my-md positive">{{ $t('common.featuredFood') }}</div>

    <!-- 活动选项兰 -->
    <q-scroll-area style="height: 200px; max-width: 100%;">
      <div class="row no-wrap">
        <div v-for="(item, index) in boxList" :key="index" style="" class="q-pa-sm">
          <div class="width-115 border-radius-5 rtl border-green">
            <!-- cover -->
            <div class="width-100-p height-100 border-radius-h" @click="onClickclassification(item)">
              <q-img class="full-height" :src="item.cover.fullUrl" />
            </div>

            <!-- name -->
            <div class="e-text-main Ukij towfm">{{ lang == 'ug' ? item.name_ug : item.name_zh }}</div>

            <!-- 添加购物车和减去、价格 -->
            <div class="row justify-between q-pr-sm">
              <div>￥{{ item.price }}</div>
              <div class="row">
                <!-- 加购物车 -->
                <div v-if="boxList[index].num == 0" class="iconel" @click="addCart(item, index)"><q-img class="full-width full-height" src="https://tunjinur.qiyat.cn/static/icons/add.svg"/></div>

                <!-- 加减购物车 -->
                <div v-if="boxList[index].num > 0" class="row bg-grey-2 e-border-r-50p">
                  <div class="iconel" @click="subtractCart(item, index)"><q-img class="full-width full-height" src="https://tunjinur.qiyat.cn/static/icons/jian.svg"/></div>
                  <div class="q-mx-sm">{{ item.num }}</div>
                  <div class="iconel" @click="addCart(item, index)"><q-img class="full-width full-height" src="https://tunjinur.qiyat.cn/static/icons/add.svg"/></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </q-scroll-area>

    <!-- 类型 -->
    <div class="rtl q-mt-sm q-mb-md display-flex width-100-p q-px-sm">
      <div class="font-12 Ukij text-center">
        <q-btn-dropdown class="bg-green text-white" :label="originalName ? foodCategoryModel : $t('common.foodCategory')">
          <q-list v-for="item in foodCategory" :key="item.id">
            <q-item clickable v-close-popup @click="onCategoryClick(item)">
              <q-item-section>
                <q-item-label class="Ukij">{{ lang == 'ug' ? item.name_ug : item.name_zh}}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </div>

    <!-- loading -->
    <div class="fixed z-index1 full-width row justify-center" v-if="loading" style="z-index: 1;">
      <q-spinner-cube color="teal-5" size="5.5em" />
    </div>

    <!-- 特色菜单 -->
    <div v-if="classification.length > 0" class="row flex-wrap q-mb-xl rtl" v-scroll="onScroll" ref="scrollFun">
      <div v-for="(item, index) in classification" :key="index" class="width-33-p q-px-sm q-mb-sm">
        <div class="border-radius-5 rtl border-green">
          <!-- cover -->
          <div class="width-100-p height-100 border-radius-h" @click="onClickclassification(item)">
            <q-img class="full-height" :src="item.cover ? item.cover.fullUrl : ''" />
          </div>

          <!-- name -->
          <div class="e-text-main Ukij towfm">{{ lang == 'ug' ? item.name_ug : item.name_zh }}</div>

          <!-- 添加购物车和减去、价格 -->
          <div class="row justify-between q-pr-sm">
            <div>￥{{ item.price }}</div>
            <div class="row">
              <!-- 加购物车 -->
              <div v-if="classification[index].num == 0" class="iconel" @click="addCart(item, index)"><q-img class="full-width full-height" src="https://tunjinur.qiyat.cn/static/icons/add.svg"/></div>

              <!-- 加减购物车 -->
              <div v-if="classification[index].num > 0" class="row bg-grey-2 e-border-r-50p">
                <div class="iconel" @click="subtractCart(item, index)"><q-img class="full-width full-height" src="https://tunjinur.qiyat.cn/static/icons/jian.svg"/></div>
                <div class="q-mx-sm">{{ item.num }}</div>
                <div class="iconel" @click="addCart(item, index)"><q-img class="full-width full-height" src="https://tunjinur.qiyat.cn/static/icons/add.svg"/></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- 提示空 -->
    <div v-if="!classification.length" class="row Ukij rtl justify-center q-mt-xl">{{$t('common.nothing')}}</div>

    <!-- 购物车 -->
    <div v-if="isCart" class="height-50 e-b-ba-3 full-width fixed bt-90 row justify-between e-border-r-50p e-hidden bg-white">
      <div class="row line-height-50 justify-between e-p-y25 items-center bg-white" style="width: 60%;">
        <div class="e-bg-main e-border-r-50 DFcenter" style="width: 40px; height: 40px"> <q-img style="width: 30px; height: 30px" src="https://tunjinur.qiyat.cn/static/icons/cart-white.svg" /> </div>
        <div class="Ukij row e-text-main">
          <div class="row q-mr-sm"> <div>{{$t('common.yuan')}}</div> <div>{{ total_price }}</div></div>
          <div> :{{$t('common.total')}} </div>
        </div>
      </div>

      <!-- 下单 -->
      <div class="Ukij rtl e-p-y25 line-height-50 text-white" @click="onOrder" style="width: 40%; background: linear-gradient(135deg, transparent 35px, #3fc43b 0) right">{{$t('common.onOrder')}}</div>
    </div>

    <!-- 饭菜详细内容 -->
    <q-dialog v-model="detailed_dialog" :position="position">
      <q-card style="width: 400px; height: 500px;">
        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn class="Ukij text-positive" flat icon="close" v-close-popup />
        </q-card-actions>
        <q-card-section class="q-pt-none" align="right">
          <p>
            <q-carousel style="height: 250px; border-radius: 10px; overflow: hidden;" swipeable animated  v-model="foodConImg" infinite :autoplay="autoplay" 
            transition-prev="slide-right" transition-next="slide-left">
              <q-carousel-slide :name="index" v-for="(item, index) in foodCon.images" :key="index" :img-src="item.fullUrl" />
            </q-carousel>
          </p>
        </q-card-section>
        <q-card-section align="right">
          <div class="text-h6 Ukij">{{lang == 'ug' ? foodCon.name_ug : foodCon.name_zh}}</div>
        </q-card-section>
        <p class="rtl text-positive q-pr-md">￥{{foodCon.price}}</p>
        <p class="Ukij rtl text-grey-7 q-pr-md"><span>{{lang == 'ug' ? foodCon.content_ug :foodCon.content_zh}}</span></p>
      </q-card>
    </q-dialog>

    <!-- tab -->
    <tab tab_active="1"></tab>
  </div>
</template>

<script>
import { ref } from 'vue';
import { debounce } from 'quasar';
import { onBeforeUnmount } from 'vue';
import tab from '../../components/tab/tab.vue';
import top_title from '../../components/topTitle/topTitle';
import { getFoods, getIdFood, getCategory, swipers, reqLogin, getuserInfo, payment, storePurchase } from '../../request/api';

export default {
  name: 'home',
  components: {
    tab,
    top_title
  },
  data() {
    return {
      icon_url: 'https://atush.vip/static/icons/',
      search: ref(''),
      slide: ref(0),
      foodConImg: ref(0),
      autoplay: ref(true),
      detailed_dialog:false,
      position: 'bottom',
      foodCategory:[],
      foodCategoryModel: 'foodCategory',
      originalName: false,
      foodValue: -1,
      boxList: [],
      Discount: [
        { id: 1, name: 'category' }
      ],
      classification: [],
      swipers:[],

      isCart: false,
      total_price: 0,
      products: [],
      sum: 0,
      sum1: 0,
      foodCon: '',

      lang: null,
      homeFullLanguageBox: localStorage.getItem('lang') ? false : true, // 是否显示满屏选择页面
      cartList: [], // 已选饭菜
      loading: false,

      // 分页
      pageNo: 1,
      totalPage: 0
    }
  },

  watch: {
    pageNo (val) {
      this.pageNo = val
      this.loading = true
      this.getPost()
    }
  },
  methods: {
    // 获取饭菜
    getPost() {
      let pageNo = this.pageNo
      let pageSize = 30
      let status = 1
      let value = this.foodValue
      getFoods(pageNo, status, value, pageSize).then(res => {
        if(res.status == 200) {
          this.isMask();
          this.totalPage = res.data.data.totalPage
          this.classification = this.classification.concat(res.data.data.data)
          this.boxList = res.data.recommend.data
        }
      }).catch(err => {
        console.log("err:", err)
      })
    },
    
    // 获取语言
    getLang(){
      if (localStorage.getItem('lang') == 'ug') {
        this.$i18n.locale = 'ug'
        this.lang_icon = 'lang-ug.svg'
        localStorage.setItem('lang', 'ug')
      } else if (localStorage.getItem('lang') == 'zh') {
        this.$i18n.locale = 'zh'
        this.lang_icon = 'lang-zh.svg'
        localStorage.setItem('lang', 'zh')
      } else {
        this.homeFullLanguageBox = true
      }

      this.lang = localStorage.getItem('lang');
    },

    // 首页满屏选择语言
    setLanguage(item) {
      localStorage.setItem('lang', item)

      this.getLang()

      this.homeFullLanguageBox = false
    },

    // 添加购物车
    addCart(item, index) {
      this.isCart = true
      let price = 0;
      let price1 = 0;
      if (item.recommend == 0) {
        this.classification[index].num++
        for (let i of this.classification) {
          price += i.num * i.price
          this.sum = price
        }
      } else if (item.recommend == 1) {
        this.boxList[index].num++
        for (let i of this.boxList) {
          price1 += i.num * i.price
          this.sum1 = price1
        }
      }

      this.total_price = this.sum + this.sum1;
    },

    // 从购物车减去
    subtractCart(item, index) {
      let price = 0;
      let price1 = 0;
      if(item.recommend == 0){
        this.classification[index].num--
        for (let i of this.classification) {
          price += i.num * i.price
          this.sum = price
        }
      }
      else if(item.recommend == 1){
        this.boxList[index].num--
        for (let i of this.boxList) {
          price1 += i.num * i.price
          this.sum1 = price1
        }
      }

      this.total_price = this.sum + this.sum1;
      
      if (this.total_price == 0 )this.isCart = false
    },

    // 下单
    onOrder() {
      for (let item of this.boxList) {
        if (item.num > 0) {
          this.cartList.push(item)
        }
      }

      for (let itemA of this.classification) {
        if (itemA.num > 0) {
          this.cartList.push(itemA)
        }
      }

      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let user_id = userInfo.id
      let workplace = userInfo.workplace_value

      // let workplace = 2
      // let user_id = 1

      let total_fee = 0
      let data = { id: user_id, total_fee: total_fee, type: 9, workplace: workplace, address_id: 0 }
      payment(data).then(res => {
        if (res.status == 200) {
          // 生成订单方法
          this.purchaseFun(res.data.out_trade_no)
        }
      }).catch(err => { })

    },

    // 生成订单
    purchaseFun (nonceStr) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let user_id = userInfo.id
      // let user_id = 1
      for (let item of this.cartList) {
        let purchase = { out_trade_no: nonceStr, price: item.price, user_id: user_id, count: item.num, goods_id: item.id }
        storePurchase(purchase).then(res => {
          if (res.status == 200) {
            console.log("已生成订单 ...")
          }
        }).catch(err => { console.log("err: ", err) })
      }

      this.$router.push({
        path: '/paymentCon',
        query: {
          out_trade_no: nonceStr
        }
      })
    },

    // 展示饭菜详细内容
    onClickclassification(item){
      let postId = item.id
      getIdFood(postId).then(res =>{
        this.foodCon = res.data;
      }).catch(err => {
        console.log('err: ', err)
      })
      this.detailed_dialog = true;
    },

    // 获取餐饮类型
    getSelection() {
      getCategory().then(res => {
        this.foodCategory = res.data
      }).catch(err => {
        console.log("err:", err)
      })
    },

    // 选择 food 类型
    onCategoryClick(item) {
      let lang = localStorage.getItem('lang');
      if (lang == 'ug') {
        this.foodCategoryModel = item.name_ug
        this.originalName = true
      }else if (lang == 'zh') {
        this.foodCategoryModel = item.name_zh
        this.originalName = true
      } else {
        this.foodCategoryModel = item.name_ug
        this.originalName = true
      }
      this.foodValue = item.value
      let value = this.foodValue
      let pageNo = 1
      let status = 1
      let pageSize = 30
      getFoods(pageNo, status, value, pageSize).then(res => {
        this.classification = res.data.data.data
      }).catch(err => {
        console.log("err:", err)
      })
    },

    // 获取轮播图
    getswypr() {
      swipers().then(res => {
        this.swipers = res.data
      }).catch(err => {
        console.log("err:", err)
      })
    },

    // 登录请求
    reqLoginFun () {
      reqLogin().then(res => {
        if (res.status == 200) {
          window.location.href = res.data
        }
      }).catch()
    },

    // 判断是否有 code
    isCode () {
      if (sessionStorage.getItem("isRun")) return true;
      if (window.location.search.includes('code=')) {
        sessionStorage.setItem('isCode', this.$route.query.code)
        sessionStorage.setItem('isRun', false)
        let code = this.$route.query.code
        let data = { code }
        
        getuserInfo(data).then(res => {
          const userInfo = res.data.data
          const token = res.data.token
          this.userInfo = userInfo
          sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
          sessionStorage.setItem("token", token)
        }).catch()
      }
      return sessionStorage.getItem("isCode") !== null;
    },

     // 获取用户信息
    getLocalUserInfo () {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 点击轮播图
    onClickSwiper(item) {
      console.log("item: ", item)
    },

    // 加载图标
    isMask() {
      setTimeout(() => {
        this.loading = false;
      }, 2000)
    },

    // 分页
    onScroll: debounce(function (position) {
      // let scrollTop = this.$refs.scrollFun.scrollTop;
      let scrollTop = parseInt(document.documentElement.scrollTop, 10) || parseInt(document.body.scrollTop, 10);
      // let clientHeight = this.$refs.scrollFun.clientHeight;
      let clientHeight = document.documentElement.clientHeight;
      // let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let scrollHeight = document.body.scrollHeight;
      let height = 200;
      if ( scrollTop + clientHeight + height >= scrollHeight) {
        if (this.pageNo == this.totalPage) {
          return false
        } else {
          this.pageNo = this.pageNo + 1
        }
      } else {
        return false;
      }

      // if (scrollTop + clientHeight >= scrollHeight - height) {
      //   if (this.pageNo == this.totalPage) {
      //     return false
      //   } else {
      //     this.pageNo = this.pageNo + 1
      //   }
      // } else {
      //   return false
      // }

    }, 200)

  },

  created() {
    this.getLang()
    this.getPost()
    this.getSelection()
    this.getswypr()
    this.scroll_top()
    this.getLocalUserInfo()
    if (!this.isCode()) this.reqLoginFun()
  }
}
</script>
<style>
@import '../../assets/costum.css';

.home {padding-bottom: 100px;}
.q-carousel__thumbnail {width: 40px !important; height: 35px !important;}
.flex-wrap{flex-wrap:wrap;}
.width-100-p {width: 100%;}
.height-100 {height: 100px;}
.border-radius-5 {border-radius: 5px;}
.font-size-10 {font-size: 10px;}
.border-green {border: 1px solid rgb(185, 197, 189);}
.display-block {display: block;}
.border-radius-h {border-radius: 5px 5px 0 0;}
.border-bottom {border-bottom: 3px solid #06cb00;}
.w-height-t {
  width: 120px;
  height: 180px;
}
.iconel{
  width: 20px;
  height: 20px;
}
.height-190{height: 190px;}
.towfm{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  margin: 5px 0 ;
}

.q-select__dialog {
  font-family: Ukij !important;
  direction: rtl;
}
</style>