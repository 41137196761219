import { createI18n } from 'vue-i18n';   // 与vue2的VueI18n使用方式不同
import ug from './lang/ug';
import zh from './lang/zh';

const i18n = createI18n({
  locale: 'ug',
  messages: {
    'ug': ug,
    'zh': zh
  }
});

export default i18n;
