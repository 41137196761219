<template>
<div>
     <top_title :title="$t('common.address')" :backIon=true></top_title>
     
     <!-- 输入地址 -->
     <div class="df q-pt-sm justify-content">
          <div class="text-blue-grey-4 q-ml-sm Ukij width">{{ $t('common.shippingaddress') }}  :</div>
          <q-input v-model="addres_con" color="positive" class="full-height margin-left Ukij" :placeholder="$t('common.fulladdress')" :dense="dense" style="width: 300px;"/>
     </div>

     <!-- 输入手机号 -->
     <div class="df q-pt-sm justify-content">
          <div class="text-blue-grey-4 q-ml-sm Ukij width">{{ $t('common.phonenumber') }} :</div>
          <q-input :rules="[ val => val.length <= 11 || 'تېلىفون نومۇرىڭزنى توغرا تولدۇرۇڭ']" color="positive" v-model="phone_num" class="full-height margin-left Ukij" type="tel" :placeholder="$t('common.receivingmobile')" :dense="dense" style="width: 300px;"/>
     </div>

     <!-- 保存按钮 -->
     <div class="Ukij df q-mt-xl"><q-btn color="positive" :label="$t('common.save')" style="width: 370px;" @click="onClickaddress" /> </div>
</div>


</template>



<script>
import { ref } from 'vue';
import top_title from '../../components/topTitle/topTitle.vue';
import { addAddress } from '../../request/api';
export default {
  name: 'addaddress',
  components: { top_title },
  data(){
     return{
          user_id:'',
          addres_con: '',
          phone_num:'',
          dense: false,
     }
  },
 methods:{
     // 保存新增地址
     onClickaddress () {
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
          let data = { addres_con: this.addres_con, phone_num: this.phone_num, user_id: userInfo.id }
          addAddress(data).then(res => {
               if (res.status == 200) {
                    const notif = this.$q.notify({
                         position: 'center',
                         type: 'ongoing',
                         message: 'Looking up the search terms...'
                    })

                    // simulate delay
                    setTimeout(() => {
                         notif({
                              type: 'positive',
                              position: 'center',
                              message: 'Found the results that you were looking for',
                              timeout: 1000
                         })

                         // 返回地址 list
                         this.$router.go(-1)
                    }, 4000)
                    
               }
          }).catch(err => {
               console.log("err:", err)
          })
     },
     
     // 获取编辑内容
     getmyaddress(){
          let myaddress = JSON.parse(this.$route.query.userInfo)
          this.addres_con = myaddress.addres_con
          this.phone_num = myaddress.phone_num
          this.user_id = myaddress.id
     }
  },
  created () {
     this.getmyaddress()
  }
}

</script>


<style>
@import '../../assets/costum.css';
.width{width: 70px;}
.margin-left{margin-left: 23px;}
.justify-content{justify-content: space-between;}
</style>