<template>
  <div class="mine">
    <!-- top -->
    <div class="e-bg-main height-200 column align-center justify-end">
      <div class="column align-center justify-center">
        <div class="width-50 height-50"><q-img class="full-width full-height" src="https://tunjinur.qiyat.cn/static/icons/TN_LOGO.svg" /></div>
        <div class="Ukij text-white font-20">{{ $t('common.tunji_nur') }}</div>
      </div>

      <!-- avatar and name -->
      <div v-if="userInfo" class=" relative-position bt-fu-50 width-80-p e-bg-main border-radius-10 border ml rtl dfl justify-around">
        <div class="justify-around"><q-img class="height-50 q-my-md e-border-r-50" style="width: 50px;" :src="userInfo.headimgurl" /></div>
        <div class="dfl text-grey-1 column justify-center">
          <div><span class="Ukij">{{ $t('common.welcome') }} : </span><span class="q-mx-sm">{{ userInfo.nickname }}</span></div>
          <div>No: {{ userInfo.id }}</div>
        </div>
      </div>
    </div>

    <!-- header -->
    <div v-if="userInfo" class="e-mt-70 row justify-center rtl ">
      <div class="column items-center width-40-p q-py-md text-white bg-orange-4 border-radius-20" @click="onClickHeaderList(1)">
        <div class="">{{ balanceCon.balance / 100 }} <span>￥</span></div>
        <div class="Ukij">{{ $t(`common.balance`) }}</div>
      </div>

      <div class="column items-center width-40-p q-py-md text-white bg-green border-radius-20-r" @click="onClickHeaderList(2)">
          <div class="">{{ balanceCon.points }}</div>
          <div class="Ukij">{{ $t(`common.integral`) }}</div>
        </div>
    </div>

    <!-- main_list -->
    <div class="q-px-md q-mt-xl">
      <div class="row justify-between q-py-md q-px-sm e-border-r-20 e-bg-grey-4 q-mb-md" v-for="(item, index) in main_list" :key="index" @click="onClickMainList(item)">
        <div><q-img v-if="index == 0" class="width-13" style="width: 50px; height: 18px;" :src="'https://tunjinur.qiyat.cn/static/icons/' + lang_icon" /></div>
        <div class="row items-center items-center">
          <div :class="['Ukij q-mx-sm font-12', index == 0 ? 'e-text-green' : index == 1 ? 'text-blue-4' : index == 2 ? 'e-text-purple' : index == 3 ? 'e-text-orange' :index == 4 ? 'e-text-yellow' : '' ]">{{ $t(`common.${item.name}`) }}</div>
          <div><q-img style="width: 20px; height: 20px;" :src="item.icon" /></div>
        </div>
      </div>
    </div>

    <!-- 退出 -->
    <div class="q-px-md q-mt-xl">
      <q-btn class="Ukij full-width" color="primary" :label="$t('common.quit')" @click="quit" />
    </div>

    <!-- 联系对话框 -->
    <q-dialog v-model="contact_dialog">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section align="right">
          <div class="text-h6 Ukij">تۇنجى نۇر مەسئۇلى</div>
        </q-card-section>

        <q-card-section class="q-pt-none" align="right">
          <p class="Ukij text-grey-5"><span>زۇلپىقار:</span> <a class="text-grey-5 text-decoration" href="tel:13199850773">13199850773</a></p>
          <p><q-img src="https://tunjinur.qiyat.cn/static/images/Tunji_Nur.png" /></p>
        </q-card-section>

        <q-card-actions align="left" class="bg-white text-teal">
          <q-btn class="Ukij" flat label="تامام" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 技术提供商 -->
    <div class="q-my-xl column justify-between items-center text-grey-5">
      <div>阿图什市科雅特信息技术服务有限公司提供技术</div>
      <div>Atush City Qiyat Information Technology Service Co. LTD</div>
    </div>

    <!-- tab -->
    <tab tab_active="3"></tab>
  </div>
</template>

<script>

import tab from '../../components/tab/tab';
import { getBalance } from '../../request/api';

  export default {
    name: 'mine',
    components: {
      tab
    },

    data() {
      return {
        lang_type: true, // 切换语言 true: ug, false: zh
        contact_dialog: false,  // 联系对话框
        lang_icon: 'lang-ug.svg',
        main_list: [
          { id: 1, name: 'lang', icon: 'https://tunjinur.qiyat.cn/static/icons/lang.svg' },
          { id: 5, name: 'myaddress', icon: 'https://bpic.51yuansu.com/pic3/cover/03/44/93/5bc581659a30f_610.jpg' },
          { id: 2, name: 'contact', icon: 'https://tunjinur.qiyat.cn/static/icons/phone.svg' },
          { id: 3, name: 'complaints', icon: 'https://tunjinur.qiyat.cn/static/icons/complaints.svg' },
          { id: 4, name: 'tn_about', icon: 'https://tunjinur.qiyat.cn/static/icons/about.svg' }
        ],
        userInfo: '', // 用户信息
        balanceCon: '', // 余额信息
      }
    },

    methods: {
      // 获取余额
      requestBalance(){
        getBalance().then(res => {
          this.balanceCon = res.data
        }).catch(err => {
          console.log("err: ", err)
        })
      },

      // 到充值或积分页面
      onClickHeaderList(item) {
        switch (item) {
          case 1:
            this.$router.push({ path: '/filled' })
            break;
          case 2:
            this.$router.push({ path: '/integral' })
            break;
          default:
            console.log("默认")
            break;
        }
      },

      // main_list on click
      onClickMainList(item) {
        let lang = localStorage.getItem('lang')
        switch (item.id) {
          case 1:
            switch (lang) {
              case 'ug':
                this.$i18n.locale = 'zh'
                this.lang_icon = 'lang-zh.svg'
                localStorage.setItem('lang', 'zh');
                break;
              case 'zh':
                this.$i18n.locale = 'ug'
                this.lang_icon = 'lang-ug.svg';
                localStorage.setItem('lang', 'ug')
                break;
              default:
                console.log("默认")
                break;
            }
            break;
          case 2:
            this.contact_dialog = true;
            break;
          case 3:
            this.$router.push({ path: '/complaints' })
            break;
          case 4:
            this.$router.push({ path: '/about' })
            break;
          case 5:
            this.$router.push({ path: '/address' })
            break;
          default:
            console.log("默认")
            break;
        }
      },

      // 滚动到顶部
      scroll_top () {
        window.scrollTo(0, 0);
      },

      // 获取用户信息
      getLocalUserInfo () {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      },

      // 清除缓存
      quit() {
        sessionStorage.clear()
        localStorage.clear()
        this.$router.push({ path: '/' })
      },

      // 语言设置
      languageFun() {
        if (localStorage.getItem('lang') == 'ug') {
          this.$i18n.locale = 'ug'
          this.lang_icon = 'lang-ug.svg'
          localStorage.setItem('lang', 'ug')
        } else if (localStorage.getItem('lang') == 'zh') {
          this.$i18n.locale = 'zh'
          this.lang_icon = 'lang-zh.svg'
          localStorage.setItem('lang', 'zh')
        } else {
          this.$i18n.locale = 'ug'
          this.lang_icon = 'lang-ug.svg'
          localStorage.setItem('lang', 'ug')
        }
      }
    },

    created() {
      this.scroll_top()
      this.getLocalUserInfo()
      this.languageFun()
      this.requestBalance()
    }
  }
</script>

<style>
.mine { padding-bottom: 100px }
.border-radius-20{border-radius:0 35px 35px 0;}
.border-radius-20-r{border-radius:35px 0 0 35px;}
.width-40-p{width: 40%;}
.bg-green {background: #06cb00 !important;}
.border{border: 3px solid #fff;}
.border-radius-10{border-radius: 30px;}
.dfl{display: flex;}
.din{display:inline-block;}
.g{align-items: center;}
.bg-orange{background-color: #ffc303;}
.text-positive{color: #06cb00;}
.text-decoration{text-decoration: none;}
</style>